import { Component, OnInit, ViewChild } from '@angular/core';
import { CaseService } from 'src/app/services/case.service';
import { MasterService } from 'src/app/services/master.service';
import { Observable, Subject, forkJoin, of } from 'rxjs';

import { DataTableDirective } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { GridApi } from 'ag-grid-community';
import { switchMap } from 'rxjs/operators';
import { AgGridService } from 'src/app/services/ag-grid.service';
import { AgGridComponent } from 'src/app/sheard-grid/ag-grid/ag-grid.component';

@Component({
  selector: 'app-compliance-list-disposed',
  templateUrl: './compliance-list-disposed.component.html',
  styleUrls: ['./compliance-list-disposed.component.css']
})
export class ComplianceListDisposedComponent implements OnInit {
  getDataVal: any;
  title = 'datatables';
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  @ViewChild('noteModal') noteModal: any;
  year: any = [];
  getDataO: any;
  saveForm: any;
  isValidFormSubmitted!: boolean;
  getSubData: any[] = [];
  getAllCourtData: any[] = [];
  getDataDist: any[] = [];
  getAllCaseSubject: any[] = [];
  allCaseTypeData: any[] = [];
  filteredCaseSubject: any = [];

  @ViewChild('agGrid') gridComponent!: AgGridComponent;
  // dataSource: any[] = [];
  @ViewChild(DataTableDirective, { static: false })
  datatableElement!: DataTableDirective;
  private filterState: { [key: string]: any } = {};
  constructor(
    private CaseS: CaseService,
    private MasterS: MasterService,
    private spinner: NgxSpinnerService,
    private agGridService: AgGridService
  ) { }

  colDefs: any[] = [
    { field: "case_no", resizable: true, headerName: 'CASE NO. / वाद संख्या', floatingFilter: true, },
    { field: "case_year", resizable: true, headerName: 'YEAR / वर्ष', floatingFilter: true, },
    { field: "case_court_id.court_name", resizable: true, headerName: 'COURT / न्यायालय', floatingFilter: true, },
    { field: "case_subjectType", resizable: true, headerName: 'Case Subject / वाद विषय', floatingFilter: true, },
    { field: "case_subject.case_subject_name", resizable: true, headerName: 'Case Subsubject / वाद उपविषय', floatingFilter: true, },
    { field: "respondent", resizable: true, headerName: 'Respondent', floatingFilter: true, },
    { field: "case_section_id.section_name", resizable: true, headerName: 'GOVT. SECTION / शासन अनुभाग', floatingFilter: true, },
    { field: "case_status_id.case_status_name", resizable: true, headerName: 'Case Status', floatingFilter: true, },
    { field: "case_district_id.district", resizable: true, headerName: 'District / जिला', floatingFilter: true, },
    { field: "petitioner", resizable: true, headerName: 'Petitioners', floatingFilter: true, },
    // { field: "disposedDate", resizable: true, headerName: 'Disposed Date / निस्तारित तिथि', floatingFilter: true, },
    // { field: "complianceDate", resizable: true, headerName: 'Due Date For compliance /अनुपालन के लिए नियत तिथि', floatingFilter: true, },
    {
      field: "case_orgnaization_id.org_name",
      filterType: 'text', resizable: true, headerName: 'DEPARTMENT / विभाग', floatingFilter: true,
    },
    { field: "case_sun_org_id.sub_org_name", resizable: true, headerName: 'ORGANIZATION / CE', floatingFilter: true, },
    { field: "case_se_id.se_name", resizable: true, headerName: 'SE', floatingFilter: true, },
    { field: "ee_name", resizable: true, headerName: 'EE', floatingFilter: true, },
    { field: "case_contempt", resizable: true, headerName: 'Contempt', floatingFilter: true, },
    { field: "case_slp", resizable: true, headerName: 'SLP', floatingFilter: true, },
    { field: "hod", resizable: true, headerName: 'HOD Office Case', floatingFilter: true, },
    { field: "PIL", resizable: true, headerName: 'PIL', floatingFilter: true, },
    { field: "PSR", resizable: true, headerName: 'Principal Secretary as a respondent', floatingFilter: true, },
    { field: "CSR", resizable: true, headerName: 'Chief Secretary as a respondent', floatingFilter: true, },
    { field: "case_affidavit_field", resizable: true, headerName: 'Affidavit Filed / प्रतिशपथ पत्र दाख़िला', floatingFilter: true, },
    { field: "case_affidavit_filing_date", resizable: true, headerName: 'Affidavit Filing Date', floatingFilter: true, },
    { field: "case_classification_id.case_classi_name", resizable: true, headerName: 'Classification / वाद वर्गीकरण', floatingFilter: true, },
    { field: "case_ho_id.ho_name", resizable: true, headerName: 'H.O. SECTION / CELL', floatingFilter: true, },
    { field: "case_rejoinder", resizable: true, headerName: 'REJOINDER', floatingFilter: true, },
    { field: "case_rejoinder_date", resizable: true, headerName: 'REJOINDER DATE', floatingFilter: true, },
  ];

  remoteGridBinding = this;

  applyFilter(event: any, key_name: string) {

    const target = event.target as HTMLInputElement;
    const searchValue = target.value.trim();

    // Update the filter state with the new filter value
    this.filterState[key_name] = {
      filterType: "text",
      type: "contains",
      filter: searchValue
    };

    // Create the filter model from the filter state
    const filterModel = { filterModel: this.filterState };

    // Apply the filters to the grid
    if (this.gridComponent) {
      this.gridComponent.applyFilters(filterModel);
    } else {

    }
  }

  getData(params: any) {
    this.spinner.show()
    let userID = localStorage.getItem("id");
    let filters = this.agGridService.queryModifier([params.filterModel])
    if (Object.keys(filters).length) {
      let filteredData = this.CaseS.filterData(this.getDataVal, filters)
      this.spinner.hide()
      return of({ data: filteredData, totalRecords: filteredData.length });
    } else {
      if (this.getDataVal) {
        this.spinner.hide()
        return of({ data: this.getDataVal, totalRecords: this.getDataVal.length });
      }
      return this.CaseS.GetCaseDataSearch(userID, params, { 'case_status_id': '643cd3a1f9945d7d0aed25ab' }, {}).pipe(
        switchMap((data: any) => {
          this.getDataVal = this.filterPetitioner(data.resultData);
          this.spinner.hide();
          return of({ data: this.getDataVal, totalRecords: data.totalRecords });
        })
      );
    }
  }

  ngOnInit(): void { this.spinner.show(); this.getAllData(); }

  filterPetitioner(data: any): any {
    if (data?.length > 0) {
      return data.filter((item: any) => {
        if (item.case_petitioner_id?.length > 0) {
          let pets = item.case_petitioner_id.map((data: any) => data.name)
          item['petitioner'] = pets.join(", ")
        }
        if (item.case_ee_id?.length > 0) {
          item['ee_name'] = item.case_ee_id.map((data: any) => data.ee_name).toString();
        } else {
          item['ee_name'] = item.case_ee_id?.ee_name;
        }
        if (item.case_respondent_id?.length > 0) {
          let res = item.case_respondent_id.map((data: any) => data.res_name)
          item['respondent'] = res.join(", ")
        }
        return item;
      });
    }
  }

  getAllData() {
    const subOrgData$ = this.MasterS.GetSubOrgdata();
    const courtData$ = this.MasterS.GetCourtdata();
    const districtData$ = this.MasterS.GetDistrictata();
    const caseTypeData$ = this.MasterS.GetTypedata();
    const caseSubject$ = this.MasterS.GetCaseSubject();

    forkJoin([
      subOrgData$,
      courtData$,
      districtData$,
      caseTypeData$,
      caseSubject$
    ]).subscribe(([subOrgResponse, courtResponse, districtResponse, caseTypeResponse, caseSubjectResponse]: any) => {
      this.getSubData = subOrgResponse.resultData.map((item: any) => {
        item.sub_org_name = item.sub_org_name.replace(/\(.*?\)/g, '').trim();
        return item;
      });

      this.getAllCourtData = courtResponse.resultData.map((item: any) => {
        item.court_name = item.court_name.replace(/\(.*?\)/g, '').trim();
        return item;
      });

      this.getDataDist = districtResponse.resultData.map((item: any) => {
        item.district = item.district.replace(/\(.*?\)/g, '').trim();
        return item;
      });

      this.allCaseTypeData = caseTypeResponse.resultData;

      this.getAllCaseSubject = caseSubjectResponse.resultData.map((item: any) => {
        item.case_subject_name = item.case_subject_name.replace(/\(.*?\)/g, '').trim();
        return item;
      });
    });
  }

  filterCaseSubjects(event: any) {
    let type = event.target?.value
    this.applyFilter({ target: { value: '' } }, "case_subject.case_subject_name")
    this.filteredCaseSubject = this.getAllCaseSubject?.filter((subj: any) => subj?.case_subjectType?.includes(type))
  }

}
