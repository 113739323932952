import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { base_url } from '../global';
import { base_url_file } from '../global';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: "root",
})
export class CaseService {
  constructor(private http: HttpClient, private router: Router) { }

  saveCase(data: any) {
    let api_url = base_url + "add-case";
    const httpOptions = {
      headers: new HttpHeaders({
        "content-type": "application/json;charset=UTF-8",
      }),
    };
    return this.http.post(api_url, data, httpOptions);
  }

  GetCaseDatas(id: any) {

    let api_url = base_url + "get-all-cases";
    let qureyParams = new HttpParams();
    qureyParams = qureyParams.append("userID", id);
    // qureyParams.append("pageSize", data.pageSize);


    const httpOptions = {
      headers: new HttpHeaders({
        "content-type": "application/json;charset=UTF-8",
      }),
      params: qureyParams,
    };

    return this.http.get(api_url, httpOptions);
  }


  getAOne(params: any) {
    let api_url = `${base_url}getAOne?case_court_id=${params.case_court_id}`;
    let body: any = {
      case_sun_org_id: params.department_id
    }
    const httpOptions = {
      headers: new HttpHeaders({
        "content-type": "application/json;charset=UTF-8",
      })
    };

    return this.http.post(api_url, body, httpOptions);
  }

  getBOne(params: any) {

    let api_url = `${base_url}getBOne?case_court_id=${params.case_court_id}`;

    let body: any = {
      case_sun_org_id: params.department_id
    }
    const httpOptions = {
      headers: new HttpHeaders({
        "content-type": "application/json;charset=UTF-8",
      })
    };

    return this.http.post(api_url, body, httpOptions);
  }

  GetCaseData(id: any, params?: any): Observable<any> {
    let api_url = base_url + `get-case?startRow=${params.startRow}&endRow=${params.endRow}&userID=${id}`;
    let qureyParams = new HttpParams();
    qureyParams.set('userID', id).set('startRow', params.startRow).set('endRow', params.endRow);

    const httpOptions = {
      headers: new HttpHeaders({
        "content-type": "application/json;charset=UTF-8",
      }),

    };
    return this.http.get(api_url, httpOptions)
  }

  GetCaseSearch(body: any): Observable<any> {
    let api_url = base_url + `dashboard-search`;
    const httpOptions = {
      headers: new HttpHeaders({
        "content-type": "application/json;charset=UTF-8",
      }),
    };
    return this.http.post(api_url, body, httpOptions)
  }

  GetCaseData2(id: any): Observable<any> {
    // if (!this.dataFetched) {
    let api_url = base_url + `get-case?userID=${id}`;
    let qureyParams = new HttpParams();
    qureyParams.set('userID', id);

    const httpOptions = {
      headers: new HttpHeaders({
        "content-type": "application/json;charset=UTF-8",
      }),

    };
    return this.http.get(api_url, httpOptions)
  }

  GetCaseDataSearch(id: any, params?: any, data?: any, sort?: any): Observable<any> {
    let api_url = base_url + `get-case-search?startRow=${params.startRow}&endRow=${params.endRow}&userID=${id}`;
    const httpOptions = {
      headers: new HttpHeaders({
        "content-type": "application/json;charset=UTF-8",
      }),
    };
    let body: any = {}
    body['data'] = data;
    body['sort'] = sort;
    return this.http.post(api_url, body, httpOptions)
  }

  getALLCase(id: any) {
    let api_url = base_url + "getALLCase";
    let qureyParams = new HttpParams();
    qureyParams = qureyParams.append("userID", id);
    const httpOptions = {
      headers: new HttpHeaders({
        "content-type": "application/json;charset=UTF-8",
      }),
      params: qureyParams,
    };
    return this.http.get(api_url, httpOptions);
  }
  getSLPCaseData() {
    let api_url = base_url + "getSLPCaseData";
    const httpOptions = {
      headers: new HttpHeaders({
        "content-type": "application/json;charset=UTF-8",
      }),
    };
    return this.http.get(api_url, httpOptions);
  }

  getcase_contemptCaseData() {
    let api_url = base_url + "getcase_contemptCaseData";
    const httpOptions = {
      headers: new HttpHeaders({
        "content-type": "application/json;charset=UTF-8",
      }),
    };
    return this.http.get(api_url, httpOptions);
  }
  getCaseLast10Data() {
    let api_url = base_url + "getCaseLast10Data";
    const httpOptions = {
      headers: new HttpHeaders({
        "content-type": "application/json;charset=UTF-8",
      }),
    };
    return this.http.get(api_url, httpOptions);
  }

  GetCaseSingle(id: any) {
    let api_url = base_url + "edit-case/" + id;
    const httpOptions = {
      headers: new HttpHeaders({
        "content-type": "application/json;charset=UTF-8",
      }),
    };
    return this.http.get(api_url, httpOptions);
  }

  updateCase(data: any, id: any) {
    let api_url = base_url + "update-case/" + id;
    const httpOptions = {
      headers: new HttpHeaders({
        "content-type": "application/json;charset=UTF-8",
        // 'apikey': this.api_key
      }),
    };
    return this.http.post(api_url, data, httpOptions);
  }

  uploadFile(data: File) {
    // let api_file_url = base_url_file + 'upload';
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'content-type': 'application/json;charset=UTF-8',
    //     // 'apikey': this.api_key
    //   }),
    // };
    // return this.http.post(api_file_url, data);

    // upload(file: File): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    formData.append("file", data);
    const request = new HttpRequest(
      "POST",
      base_url_file + "upload/upload",
      formData,
      {
        reportProgress: false,
        responseType: "text",
      }
    );

    return this.http.request(request);
  }
  importCase(data: any) {
    let api_url = base_url + "addCaseImport";
    const httpOptions = {
      headers: new HttpHeaders({
        "content-type": "application/json;charset=UTF-8",
        // 'apikey': this.api_key
      }),
    };
    return this.http.post(api_url, data, httpOptions);
  }

  deleteCase(id: any) {
    let api_url = base_url + "delete-case/" + id;
    const httpOptions = {
      headers: new HttpHeaders({
        "content-type": "application/json;charset=UTF-8",
      }),
    };
    return this.http.get(api_url, httpOptions);
  }

  saveCaseDetails(data: any) {
    let api_url = base_url + "add-caseDetails";
    const httpOptions = {
      headers: new HttpHeaders({
        "content-type": "application/json;charset=UTF-8",
      }),
    };
    return this.http.post(api_url, data, httpOptions);
  }

  editCaseDetails(id: any) {
    let api_url = base_url + "edit-CaseDetails/" + id;
    const httpOptions = {
      headers: new HttpHeaders({
        "content-type": "application/json;charset=UTF-8",
      }),
    };
    return this.http.get(api_url, httpOptions);
  }

  updateCaseDetails(data: any, id: any) {
    let api_url = base_url + "update-CaseDetails/" + id;
    const httpOptions = {
      headers: new HttpHeaders({
        "content-type": "application/json;charset=UTF-8",
        // 'apikey': this.api_key
      }),
    };
    return this.http.post(api_url, data, httpOptions);
  }

  /////////////////////////////dashboard///////////////////////dashboard

  dashboard() {
    let api_url = base_url + "dashboard";
    const httpOptions = {
      headers: new HttpHeaders({
        "content-type": "application/json;charset=UTF-8",
      }),
    };
    return this.http.get(api_url, httpOptions);
  }

  newdashboard(body: any, queryParam: any) {
    let api_url = base_url + "new-dashboard" + "?userID=" + queryParam.userID;
    const httpOptions = {
      headers: new HttpHeaders({
        "content-type": "application/json;charset=UTF-8",
      }),
    };
    return this.http.post(api_url, body, httpOptions);
  }

  moreDashboard(body: any) {
    let api_url = base_url + "get-info";
    const httpOptions = {
      headers: new HttpHeaders({
        "content-type": "application/json;charset=UTF-8",
      }),
    };
    return this.http.post(api_url, body, httpOptions);
  }

  moreOrgData(body: any) {
    let api_url = base_url + "get-se-ee";
    const httpOptions = {
      headers: new HttpHeaders({
        "content-type": "application/json;charset=UTF-8",
      }),
    };
    return this.http.post(api_url, body, httpOptions);
  }

  getAllCourtData() {
    let api_url = base_url + "getAllCourtData";
    const httpOptions = {
      headers: new HttpHeaders({
        "content-type": "application/json;charset=UTF-8",
      }),
    };
    return this.http.get(api_url, httpOptions);
  }

  getCaseSubjectWithCount() {
    let api_url = base_url + "getCaseSubjectWithCount";
    const httpOptions = {
      headers: new HttpHeaders({
        "content-type": "application/json;charset=UTF-8",
      }),
    };
    return this.http.get(api_url, httpOptions);
  }

  dashboardData(data: any) {
    let api_url = base_url + "dashboardData";
    const httpOptions = {
      headers: new HttpHeaders({
        "content-type": "application/json;charset=UTF-8",
      }),
    };
    return this.http.post(api_url, data, httpOptions);
  }

  dashboardCaseNoseraching(data: any) {
    let api_url = base_url + "dashboardCaseNoseraching";
    const httpOptions = {
      headers: new HttpHeaders({
        "content-type": "application/json;charset=UTF-8",
      }),
    };
    return this.http.post(api_url, data, httpOptions);
  }

  filterData(data: any, query: any) {
    return data.filter((item: any) => {
      return Object.keys(query).every(key => {
        if (key.includes('.')) {
          const [outerKey, innerKey] = key.split('.');
          return item[outerKey] && item[outerKey][innerKey] && item[outerKey][innerKey].toString().toLowerCase().includes(query[key].toLowerCase());
        }
        return item[key] && item[key].toString().toLowerCase().includes(query[key].toLowerCase());
      });
    });
  }

  exportToCsv(exportData: any[], filename: string) {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to export the data as a CSV file!",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Yes, download it!",
      cancelButtonText: "Cancel!",
      allowOutsideClick: false
    }).then((result) => {
      let csvData = []
      if (result.isConfirmed) {
        // console.log('export');
        csvData = exportData.map((data: any) => {
          return {
            'CASE NO. / वाद संख्या': data?.case_no || '',
            'CASE YEAR / वाद वर्ष': data?.case_year || '',
            'Petitioners': data?.petitioner || '',
            'Respondent': data?.respondent || '',
            'COURT / न्यायालय': data?.case_court_id?.court_name || '',
            'GOVT. SECTION / शासन अनुभाग': data?.case_section_id?.section_name || '',
            'EE': data?.ee_name || '',
            'SE': data?.case_se_id?.se_name || '',
            'ORGANIZATION / CE': data?.case_sun_org_id?.sub_org_name || '',
            'CASE SUBJECT / वाद विषय': data?.case_subjectType || '',
            'CASE SUB SUBJECT / वाद उपविषय': data?.case_subject?.case_subject_name || '',
            // 'Case Type / वाद प्रकार ': data?.case_type_id?.case_type_name || '',
            'Case Status': data?.case_status_id?.case_status_name || '',
            'District / जिला': data?.case_district_id?.district || '',
            'DEPARTMENT / विभाग': data?.case_orgnaization_id?.org_name || '',
            'Contempt': data?.case_contempt || '',
            'SLP': data?.case_slp || '',
            'HOD Office Case': data?.hod || '',
            'PIL': data?.PIL || '',
            'Principal Secretary as a respondent': data?.PSR || '',
            'Chief Secretary as a respondent': data?.CSR || '',
            'Affidavit Filed / प्रतिशपथ पत्र दाख़िला': data?.case_affidavit_field || '',
            'Affidavit Filing Date': data?.case_affidavit_filing_date || '',
            'Classification / वाद वर्गीकरण': data?.case_classification_id?.case_classi_name || '',
            'H.O. SECTION / CELL': data?.case_ho_id.ho_name || '',
            'REJOINDER': data?.case_rejoinder || '',
            'REJOINDER DATE': data?.case_rejoinder_date || '',
          }
        });

        const csv = this.convertToCSV(csvData);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');

        if (link.download !== undefined) { // feature detection
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', filename + '.csv');
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    });
  }

  convertToCSV(objArray: any[]): string {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    const headers = Object.keys(array[0]);

    str += headers.join(',') + '\r\n';

    for (const item of array) {
      const line = headers.map(header => `"${item[header] || ''}"`).join(',');
      str += line + '\r\n';
    }

    return str;
  }

  sortData(data: any[], sortKey: string, sortOrder: 'asc' | 'desc' = 'asc'): any[] {
    return data.sort((a, b) => {
      const aValue = this.getNestedValue(a, sortKey);
      const bValue = this.getNestedValue(b, sortKey);
      const aStr = aValue ? aValue.toString().toLowerCase() : '';
      const bStr = bValue ? bValue.toString().toLowerCase() : '';

      if (aStr < bStr) {
        return sortOrder === 'asc' ? -1 : 1;
      }
      if (aStr > bStr) {
        return sortOrder === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }

  getNestedValue(obj: any, path: string): any {
    return path.split('.').reduce((o, p) => o ? o[p] : undefined, obj);
  }
}

