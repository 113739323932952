import { Component, OnInit, ViewChild } from '@angular/core';
import { CaseService } from 'src/app/services/case.service';
import { MasterService } from 'src/app/services/master.service';
import { Observable, Subject, of } from 'rxjs';

import { DataTableDirective } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { GridOptions, IDatasource, IGetRowsParams, GridApi } from 'ag-grid-community';
import { switchMap } from 'rxjs/operators';
import { AgGridComponent } from 'src/app/sheard-grid/ag-grid/ag-grid.component';

@Component({
  selector: 'app-slp-list',
  templateUrl: './slp-list.component.html',
  styleUrls: ['./slp-list.component.css']
})
export class SlpListComponent implements OnInit {
  getDataVal: any;
  title = 'datatables';
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  @ViewChild('noteModal') noteModal: any;
  year: any = [];
  getDataO: any;
  saveForm: any;
  isValidFormSubmitted!: boolean;
  // dataSource: any[] = [];
  getSubData: any[] = [];
  getAllCourtData: any[] = [];
  getDataDist: any[] = [];
  getAllCaseSubject: any[] = [];
  allCaseTypeData: any[] = [];
  @ViewChild(DataTableDirective, { static: false })
  datatableElement!: DataTableDirective;
  @ViewChild('agGrid') gridComponent!: AgGridComponent;
  filteredCaseSubject: any[] = [];
  constructor(
    private CaseS: CaseService,
    private MasterS: MasterService,
    private spinner: NgxSpinnerService,
  ) {


  }

  colDefs: any[] = [
    { field: "case_no", resizable: true, headerName: 'CASE NO. / वाद संख्या', floatingFilter: true, },
    { field: "case_year", resizable: true, headerName: 'YEAR / वर्ष', floatingFilter: true, },
    { field: "case_court_id.court_name", resizable: true, headerName: 'COURT / न्यायालय', floatingFilter: true, },
    { field: "case_subjectType", resizable: true, headerName: 'Case Subject / वाद विषय', floatingFilter: true, },
    { field: "case_subject.case_subject_name", resizable: true, headerName: 'Case Subsubject / वाद उपविषय', floatingFilter: true, },

    { field: "respondent", resizable: true, headerName: 'Respondent', floatingFilter: true, },
    { field: "case_section_id.section_name", resizable: true, headerName: 'GOVT. SECTION / शासन अनुभाग', floatingFilter: true, },
    { field: "case_status_id.case_status_name", resizable: true, headerName: 'Case Status', floatingFilter: true, },
    { field: "case_district_id.district", resizable: true, headerName: 'District / जिला', floatingFilter: true, },
    { field: "petitioner", resizable: true, headerName: 'Petitioners', floatingFilter: true, },

    {
      field: "case_orgnaization_id.org_name",
      filterType: 'text', resizable: true, headerName: 'DEPARTMENT / विभाग', floatingFilter: true,
    },

    { field: "case_sun_org_id.sub_org_name", resizable: true, headerName: 'ORGANIZATION / CE', floatingFilter: true, },
    { field: "case_se_id.se_name", resizable: true, headerName: 'SE', floatingFilter: true, },
    { field: "ee_name", resizable: true, headerName: 'EE', floatingFilter: true, },

    { field: "case_contempt", resizable: true, headerName: 'Contempt', floatingFilter: true, },
    { field: "case_slp", resizable: true, headerName: 'SLP', floatingFilter: true, },
    { field: "hod", resizable: true, headerName: 'HOD Office Case', floatingFilter: true, },
    { field: "PIL", resizable: true, headerName: 'PIL', floatingFilter: true, },
    { field: "PSR", resizable: true, headerName: 'Principal Secretary as a respondent', floatingFilter: true, },
    { field: "CSR", resizable: true, headerName: 'Chief Secretary as a respondent', floatingFilter: true, },
    { field: "case_affidavit_field", resizable: true, headerName: 'Affidavit Filed / प्रतिशपथ पत्र दाख़िला', floatingFilter: true, },
    { field: "case_affidavit_filing_date", resizable: true, headerName: 'Affidavit Filing Date', floatingFilter: true, },

    { field: "case_classification_id.case_classi_name", resizable: true, headerName: 'Classification / वाद वर्गीकरण', floatingFilter: true, },
    { field: "case_ho_id.ho_name", resizable: true, headerName: 'H.O. SECTION / CELL', floatingFilter: true, },
    { field: "case_rejoinder", resizable: true, headerName: 'REJOINDER', floatingFilter: true, },
    { field: "case_rejoinder_date", resizable: true, headerName: 'REJOINDER DATE', floatingFilter: true, },
    // { field: "case_code", resizable: true, headerName: 'DISTRICT / जिला', floatingFilter: true, },

    // { field: "case_code", resizable: true, headerName: 'AFIIDAVIT FILING DATE / प्रतिशपथ पत्र दाख़िल कराने की तिथि', floatingFilter: true, },
  ];



  remoteGridBinding = this;
  public gridApi!: GridApi;

  getData(params: any): Observable<{ data: any; totalRecords: any }> {

    let userID = localStorage.getItem("id");
    let filters = this.simplifyFilters([params.filterModel])
    if (Object.keys(filters).length) {
      let filteredData = this.CaseS.filterData(this.getDataVal, filters)
      this.spinner.hide()
      return of({ data: filteredData, totalRecords: filteredData.length });
    } else {
      this.spinner.show();
      return this.CaseS.GetCaseDataSearch(userID, params, { 'case_slp': 'Yes' }).pipe(
        switchMap((data: any) => {
          this.spinner.hide();
          this.getDataVal = this.filterPetitioner(data.resultData);
          return of({ data: this.getDataVal, totalRecords: data.totalRecords });
        })
      );
    }


  }

  private filterState: { [key: string]: any } = {};

  applyFilter(event: any, key_name: string) {

    const target = event.target as HTMLInputElement;
    const searchValue = target.value.trim();

    // Update the filter state with the new filter value
    this.filterState[key_name] = {
      filterType: "text",
      type: "contains",
      filter: searchValue
    };

    // Create the filter model from the filter state
    const filterModel = { filterModel: this.filterState };

    // Apply the filters to the grid
    if (this.gridComponent) {
      this.gridComponent.applyFilters(filterModel);
    } else {

    }
  }

  simplifyFilters(filterArray: any) {
    let result: any = {};

    filterArray.forEach((filterObject: any) => {
      for (let key in filterObject) {

        if (filterObject.hasOwnProperty(key)) {
          const filterDetails = filterObject[key];
          result[key] = filterDetails.filter;

        }
      }
    });

    return result;
  }

  convertToMongoQuery(filterObject: any) {
    const query: any = {
      $and: []
    };

    for (const key in filterObject) {

      if (filterObject.hasOwnProperty(key)) {
        const regexObject = {
          $regex: filterObject[key],
          $options: "i"  // Case insensitive search
        };
        const fieldQuery: any = {};
        fieldQuery[key] = regexObject;
        query.$and.push(fieldQuery);
      }
    }
    query.$and.push({
      'case_slp':
      {
        "$regex": "Yes",
        "$options": "i"
      }
    });

    return query;
  }

  // globleSearchCases(searchTerm: any) {


  //   const searchTermLower = searchTerm.toLowerCase(); // Convert search term to lower case for case-insensitive comparison


  //   let data = this.remoteGridBinding.getDataVal.filter((item: any) => {
  //     // Check each field for partial match with the search term
  //     return (
  //       item.case_orgnaization_id.org_name.toLowerCase().includes(searchTermLower) ||
  //       item.case_section_id.section_name.toLowerCase().includes(searchTermLower) ||
  //       item.case_year.includes(searchTerm) ||
  //       item.case_no.includes(searchTerm) ||
  //       item.case_court_id.court_name.toLowerCase().includes(searchTermLower) ||
  //       item.case_sun_org_id.sub_org_name.toLowerCase().includes(searchTermLower) ||
  //       item.case_se_id.se_name.toLowerCase().includes(searchTermLower) ||
  //       item.case_subject.case_subject_name.toLowerCase().includes(searchTermLower) ||
  //       item.case_contempt.toLowerCase().includes(searchTermLower) ||
  //       item.case_slp.toLowerCase().includes(searchTermLower) ||
  //       item.hod.toLowerCase().includes(searchTermLower) ||
  //       item.PIL.toLowerCase().includes(searchTermLower) ||
  //       item.PSR.toLowerCase().includes(searchTermLower) ||
  //       item.CSR.toLowerCase().includes(searchTermLower) ||
  //       item.case_district_id.district.toLowerCase().includes(searchTermLower) ||
  //       item.case_affidavit_field.toLowerCase().includes(searchTermLower) ||
  //       item.case_status_id.case_status_name.toLowerCase().includes(searchTermLower) ||
  //       item.case_type_id.case_type_name.toLowerCase().includes(searchTermLower) ||
  //       item.case_classification_id.case_classi_name.toLowerCase().includes(searchTermLower) ||
  //       item.case_ho_id.ho_name.toLowerCase().includes(searchTermLower) ||
  //       item.case_rejoinder.toLowerCase().includes(searchTermLower) ||
  //       (item.case_rejoinder_date && item.case_rejoinder_date.includes(searchTerm)) // Check if date exists and includes the search term
  //     );
  //   });
  //   // console.log("Dat: ", data)
  //   this.getDatas(data);
  // }

  getDatas(data: any[]): Observable<{ data: any; totalRecords: any }> {
    // console.log(data, "hhh")
    return of({ data: data, totalRecords: data?.length });
  }

  ngOnInit(): void {
    var dateToday = new Date();
    var i;
    for (i = 0; i < 51; i++) {
      var y = (dateToday.getFullYear() - i);
      this.year.push(y);
    }
    this.spinner.show();
    let userID = localStorage.getItem("id");
    // this.CaseS.GetCaseDataSearch(userID, { startRow: '10', endRow: '20' }, { 'case_slp': 'Yes' }).subscribe((data: any) => {
    //   this.getDataVal = this.filterPetitioner(data.resultData);
    //   // this.spinner.hide();
    // });
    this.orgData();
    this.getSubOrgdata();
    this.getCourtdata();
    this.districtData();
    this.getCaseSubject();
    this.getCaseTypeData();
  }

  getSubOrgdata() {
    this.MasterS.GetSubOrgdata().subscribe((data: any) => {

      data.resultData.forEach((item: any) => {
        item.sub_org_name = item.sub_org_name.replace(/\(.*?\)/g, '').trim();
      });
      this.getSubData = data.resultData;
    });
  }

  getCourtdata() {
    this.MasterS.GetCourtdata().subscribe((data: any) => {

      data.resultData.forEach((item: any) => {
        item.court_name = item.court_name.replace(/\(.*?\)/g, '').trim();
      });
      this.getAllCourtData = data.resultData;
    });
  }

  getCaseSubject() {
    this.MasterS.GetCaseSubject().subscribe((data: any) => {

      data.resultData.forEach((item: any) => {
        item.case_subject_name = item.case_subject_name.replace(/\(.*?\)/g, '').trim();
      });
      this.getAllCaseSubject = data.resultData;


    });
  }

  districtData() {
    this.MasterS.GetDistrictata().subscribe((data: any) => {

      data.resultData.forEach((item: any) => {
        item.district = item.district.replace(/\(.*?\)/g, '').trim();
      });

      this.getDataDist = data.resultData;
    });
  }

  getCaseTypeData() {
    this.MasterS.GetTypedata().subscribe((data: any) => {

      this.allCaseTypeData = data.resultData;
    });
  }



  ngOnDestroy(): void {

  }





  orgData() {
    this.MasterS.GetOrgdata().subscribe((data: any) => {

      this.getDataO = data.resultData;
    });
  }


  filterPetitioner(data: any): any {
    if (data?.length > 0) {
      return data.filter((item: any) => {

        if (item.case_petitioner_id?.length > 0) {
          let pets = item.case_petitioner_id.map((data: any) => data.name)
          item['petitioner'] = pets.join(", ")
        }
        if (item.case_ee_id?.length > 0) {
          item['ee_name'] = item.case_ee_id.map((data: any) => data.ee_name).toString();
        } else {
          item['ee_name'] = item.case_ee_id?.ee_name;
        }
        if (item.case_respondent_id?.length > 0) {
          let res = item.case_respondent_id.map((data: any) => data.res_name)
          item['respondent'] = res.join(", ")
        }
        return item;
      });

    }
  }

  filterCaseSubjects(event: any) {
    let type = event.target?.value
    this.applyFilter({ target: { value: '' } }, "case_subject.case_subject_name")
    this.filteredCaseSubject = this.getAllCaseSubject?.filter((subj: any) => subj?.case_subjectType?.includes(type))
  }

}
