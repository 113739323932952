<div id="content" class="app-content" role="main">
    <div class="app-content-body ">
        <!-- main header -->
        <div class="lter b-b py-5 ps-4 bg-white box-shadow">
            <div class="row">
                <div class="col-sm-6 col-xs-12">
                    <h3 class="m-n font-medium">SLP</h3>
                </div>
                <div class="col-sm-6 text-right poppins hidden-xs">
                    <!-- <button class="btn btn-sm text-10 btn-addNew m-r-xs report_csv_triger" > -->
                    <!-- <a href="/add-case" class="btn btn-sm text-10 btn-addNew m-r-xs report_csv_triger" ><i
                  class="fa fa-plus"></i> Add  Case </a> -->
                    <!-- </button> -->
                    <!-- <button data-toggle="modal" data-target="#basicModal" type="submit" class="btn btn-sm text-10 btn-addNew m-r-xs "> Import</button> -->
                </div>
            </div>
        </div>
        <!-- / main header -->
        <div class="row mw">
            <div class="col-md-12">
                <div class="card no-shadow">
                    <div class="tab-content bg-seashell">
                        <div role="tabpanel" class="tab-pane active g-py-0">
                            <div class="row">

                                <div class="col-md-4">
                                    <div class="">
                                        <label for=""> Department / विभाग</label>
                                        <select class="form-control"
                                            (change)="applyFilter($event,'case_orgnaization_id.org_name')">
                                            <option selected value="">Select</option>
                                            <option value="Civil">Civil</option>
                                            <option value="Mechanical">Mechanical</option>
                                            <option value="Land Conservation">Land Conservation</option>

                                            <option value="CADA">CADA</option>

                                        </select>
                                    </div>

                                </div>


                                <div class="col-md-4">

                                    <div class="">
                                        <label for=""> Organization / CE</label>
                                        <select class="form-control"
                                            (change)="applyFilter($event,'case_sun_org_id.sub_org_name')">
                                            <option selected value="">Select</option>
                                            <option *ngFor="let data of getSubData" [value]="data?.sub_org_name">
                                                {{data?.sub_org_name}}
                                            </option>
                                        </select>
                                    </div>

                                </div>

                                <div class="col-md-4">

                                    <div class="">
                                        <label for=""> Court</label>
                                        <select class="form-control"
                                            (change)="applyFilter($event,'case_court_id.court_name')">
                                            <option selected value="">Select</option>
                                            <option *ngFor="let data of getAllCourtData" [value]="data?.court_name">
                                                {{data?.court_name}}
                                            </option>
                                        </select>
                                    </div>

                                </div>

                            </div>
                            <div class="row" style="margin-top: 24px;">
                                <div class="col-md-4">

                                    <div class="">
                                        <label for=""> District</label>
                                        <select class="form-control"
                                            (change)="applyFilter($event,'case_district_id.district')">
                                            <option selected value="">Select</option>
                                            <option *ngFor="let data of getDataDist" [value]="data?.district">
                                                {{data?.district}}
                                            </option>
                                        </select>
                                    </div>

                                </div>
                                <div class="col-md-4">
                                    <div class="">
                                        <label for=""> Case Subject</label>
                                        <select class="form-control"
                                            (change)="applyFilter($event,'case_subjectType'); filterCaseSubjects($event)">
                                            <option value=''>Select</option>
                                            <option value='भूमि'>भूमि</option>
                                            <option value='सेवा सम्बन्धी'>सेवा सम्बन्धी</option>
                                            <option value='ठेकेदार/ फर्म से सम्बन्धित'>ठेकेदार/ फर्म से सम्बन्धित</option>
                                            <option value='कार्य प्रभारित / दैनिक वेतन भोगी'>कार्य प्रभारित / दैनिक वेतन
                                                भोगी</option>
                                            <option value='अन्य'>अन्य</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="">
                                        <label for=""> Case Subsubject</label>
                                        <select class="form-control"
                                            (change)="applyFilter($event,'case_subject.case_subject_name')">
                                            <option selected value="">Select</option>
                                            <option *ngFor="let data of filteredCaseSubject" [value]="data?.case_subject_name">
                                                {{data?.case_subject_name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="margin-top: 16px;" role="tabpanel" class="tab-pane active g-py-0">
                            <div class="row row-xs">
                                <app-ag-grid #agGrid [isExpoartCsv]="true" [userList]="getDataVal"
                                    [ids]="'farmer-netural'" [colDefs]="colDefs" [paginationPageSize]="12"
                                    [remoteGridBinding]="remoteGridBinding"></app-ag-grid>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>


</div>



<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white;margin-right: 240px;">Loading...</p>
</ngx-spinner>