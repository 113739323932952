import { Component, OnInit, ViewChild } from '@angular/core';
import { CaseService } from 'src/app/services/case.service';
import { MasterService } from 'src/app/services/master.service';
import { Observable, Subject, of } from 'rxjs';

import { DataTableDirective } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { GridOptions, IDatasource, IGetRowsParams, GridApi } from 'ag-grid-community';
import { switchMap } from 'rxjs/operators';
import { EditButtonComponent } from 'src/app/sheard-grid/ag-grid/edit-button/edit-button.component';
import { AgGridService } from 'src/app/services/ag-grid.service';
import { AgGridComponent } from 'src/app/sheard-grid/ag-grid/ag-grid.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewButtonComponent } from 'src/app/sheard-grid/ag-grid/view-button/view-button.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-new-case-list',
  templateUrl: './new-case-list.component.html',
  styleUrls: ['./new-case-list.component.css']
})
export class NewCaseListComponent implements OnInit {
  getDataVal: any;
  title = 'datatables';
  // dtOptions: DataTables.Settings = {};
  // dtTrigger: Subject<any> = new Subject<any>();

  @ViewChild('noteModal') noteModal: any;
  year: any = [];
  getDataO: any;
  saveForm: any;
  isValidFormSubmitted!: boolean;
  total_record: number = 0;
  // dataSource: any[] = [];
  keys: string = '';
  getSubData: any[] = [];
  getAllCourtData: any[] = [];
  getDataDist: any[] = [];
  getAllCaseSubject: any[] = [];
  allCaseTypeData: any[] = [];
  order: any;
  query: any;
  @ViewChild('agGrid') gridComponent!: AgGridComponent;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement!: DataTableDirective;
  filteredCaseSubject: any[] = [];
  constructor(
    private CaseS: CaseService,
    private MasterS: MasterService,
    private spinner: NgxSpinnerService,
    private agGridService: AgGridService,
    private actRoute: ActivatedRoute,
    private router: Router
  ) {
    const navigation = this.router.getCurrentNavigation();
    if (navigation?.extras.state) {
      const state = navigation.extras.state as any;
      this.query = state.query;
    }
  }

  colDefs: any[] = [
    { field: "case_no", resizable: true, headerName: 'CASE NO. / वाद संख्या', floatingFilter: true, },
    { field: "case_year", resizable: true, headerName: 'YEAR / वर्ष', floatingFilter: true, },
    { field: "petitioner", resizable: true, headerName: 'Petitioners', floatingFilter: true, },
    { field: "respondent", resizable: true, headerName: 'Respondent', floatingFilter: true, },
    { field: "case_court_id.court_name", resizable: true, headerName: 'COURT / न्यायालय', floatingFilter: true, },
    { field: "case_section_id.section_name", resizable: true, headerName: 'GOVT. SECTION / शासन अनुभाग', floatingFilter: true, },
    { field: "ee_name", resizable: true, headerName: 'EE', floatingFilter: true, },
    { field: "case_se_id.se_name", resizable: true, headerName: 'SE', floatingFilter: true, },
    { field: "case_sun_org_id.sub_org_name", resizable: true, headerName: 'ORGANIZATION / CE', floatingFilter: true, },
    { field: "case_subjectType", resizable: true, headerName: 'Case Subject / वाद विषय', floatingFilter: true, },
    { field: "case_subject.case_subject_name", resizable: true, headerName: 'Case Subsubject / वाद उपविषय', floatingFilter: true, },
    { field: "case_status_id.case_status_name", resizable: true, headerName: 'Case Status', floatingFilter: true, },
    { field: "case_district_id.district", resizable: true, headerName: 'District / जिला', floatingFilter: true, },
    {
      field: "case_orgnaization_id.org_name",
      filterType: 'text', resizable: true, headerName: 'DEPARTMENT / विभाग', floatingFilter: true,
    },
    { field: "case_contempt", resizable: true, headerName: 'Contempt', floatingFilter: true, },
    { field: "case_slp", resizable: true, headerName: 'SLP', floatingFilter: true, },
    { field: "hod", resizable: true, headerName: 'HOD Office Case', floatingFilter: true, },
    { field: "PIL", resizable: true, headerName: 'PIL', floatingFilter: true, },
    { field: "PSR", resizable: true, headerName: 'Principal Secretary as a respondent', floatingFilter: true, },
    { field: "CSR", resizable: true, headerName: 'Chief Secretary as a respondent', floatingFilter: true, },
    { field: "case_affidavit_field", resizable: true, headerName: 'Affidavit Filed / प्रतिशपथ पत्र दाख़िला', floatingFilter: true, },
    { field: "case_affidavit_filing_date", resizable: true, headerName: 'Affidavit Filing Date', floatingFilter: true, },
    { field: "case_classification_id.case_classi_name", resizable: true, headerName: 'Classification / वाद वर्गीकरण', floatingFilter: true, },
    { field: "case_ho_id.ho_name", resizable: true, headerName: 'H.O. SECTION / CELL', floatingFilter: true, },
    { field: "case_rejoinder", resizable: true, headerName: 'REJOINDER', floatingFilter: true, },
    { field: "case_rejoinder_date", resizable: true, headerName: 'REJOINDER DATE', floatingFilter: true, },
    { field: "_id", cellRenderer: ViewButtonComponent, headerName: 'View' },
    { field: "_id", cellRenderer: EditButtonComponent, headerName: 'Edit' },
  ];
  remoteGridBinding = this;
  public gridApi!: GridApi;

  getData(params: any) {
    this.spinner.show()
    let userID = localStorage.getItem("id");
    let filters = this.agGridService.queryModifier([params.filterModel])
    if (Object.keys(filters).length) {
      let filteredData = this.CaseS.filterData(this.getDataVal, filters)
      this.spinner.hide()
      return of({ data: filteredData, totalRecords: filteredData.length });
    } else {
      if (this.getDataVal) {
        this.spinner.hide()
        return of({ data: this.getDataVal, totalRecords: this.getDataVal.length });
      }
      return this.CaseS.GetCaseDataSearch(userID, params, {}).pipe(
        switchMap((data: any) => {
          this.total_record = data.totalRecords;
          this.getDataVal = this.filterPetitioner(data.resultData);
          if (this.query && this.query.length) {
            this.applyFilters(this.query);
          }
          this.spinner.hide();
          return of({ data: this.getDataVal, totalRecords: data.totalRecords });
        })
      );
    }
  }

  private filterState: { [key: string]: any } = {};

  applyFilter(event: any, key_name: string) {
    const target = event.target as HTMLInputElement;
    const searchValue = target.value.trim();
    this.filterState[key_name] = {
      filterType: "text",
      type: "contains",
      filter: searchValue
    };
    const filterModel = { filterModel: this.filterState };
    // console.log(filterModel);

    if (this.gridComponent) {
      this.gridComponent.applyFilters(filterModel);
    }
  }

  applyFilters(filters: any[]) {
    const filts: any = {}
    filters.forEach((filter: any) => {
      filts[filter.name] = {
        filterType: "text",
        type: "contains",
        filter: filter.value
      }
    })
    const filterModel = { filterModel: filts };
    // console.log(filterModel);
    if (this.gridComponent) {
      this.gridComponent.applyFilters(filterModel);
    }
  }


  convertToMongoQuery(filterObject: any) {
    const query: any = {
      $and: []
    };

    for (const key in filterObject) {
      if (filterObject.hasOwnProperty(key)) {
        const regexObject = {
          $regex: filterObject[key],
          $options: "i"  // Case insensitive search
        };
        const fieldQuery: any = {};
        fieldQuery[key] = regexObject;
        query.$and.push(fieldQuery);
      }
    }

    return query;
  }

  getDatas(data: any[]): Observable<{ data: any; totalRecords: any }> {
    return of({ data: data, totalRecords: data?.length });
  }

  ngOnInit(): void {
    this.spinner.show();
    if (this.order) {
      this.applyFilter({ target: { value: this.order.replace(/\(.*?\)/g, '').trim() } }, this.query);
    }
    this.orgData();
    this.getSubOrgdata();
    this.getCourtdata();
    this.districtData();
    this.getCaseSubject();
    this.getCaseTypeData();
  }

  getSubOrgdata() {
    this.MasterS.GetSubOrgdata().subscribe((data: any) => {

      data.resultData.forEach((item: any) => {
        item.sub_org_name = item.sub_org_name.replace(/\(.*?\)/g, '').trim();
      });
      this.getSubData = data.resultData;
    });
  }

  getCourtdata() {
    this.MasterS.GetCourtdata().subscribe((data: any) => {

      data.resultData.forEach((item: any) => {
        item.court_name = item.court_name.replace(/\(.*?\)/g, '').trim();
      });
      this.getAllCourtData = data.resultData;
    });
  }

  getCaseSubject() {
    this.MasterS.GetCaseSubject().subscribe((data: any) => {

      data.resultData.forEach((item: any) => {
        item.case_subject_name = item.case_subject_name.replace(/\(.*?\)/g, '').trim();
      });
      this.getAllCaseSubject = data.resultData;


    });
  }

  districtData() {
    this.MasterS.GetDistrictata().subscribe((data: any) => {

      data.resultData.forEach((item: any) => {
        item.district = item.district.replace(/\(.*?\)/g, '').trim();
      });

      this.getDataDist = data.resultData;
    });
  }

  getCaseTypeData() {
    this.MasterS.GetTypedata().subscribe((data: any) => {

      this.allCaseTypeData = data.resultData;
    });
  }


  ngOnDestroy(): void {

  }

  filterCaseSubjects(event: any) {
    let type = event.target?.value
    this.applyFilter({ target: { value: '' } }, "case_subject.case_subject_name")
    this.filteredCaseSubject = this.getAllCaseSubject?.filter((subj: any) => subj?.case_subjectType?.includes(type))
  }
  orgData() {
    this.MasterS.GetOrgdata().subscribe((data: any) => {

      this.getDataO = data.resultData;
    });
  }


  filterPetitioner(data: any): any {
    if (data?.length > 0) {
      return data.filter((item: any) => {
        if (item.case_petitioner_id?.length > 0) {
          let pets = item.case_petitioner_id.map((data: any) => data.name)
          item['petitioner'] = pets.join(", ")
        }
        if (item.case_ee_id?.length > 0) {
          item['ee_name'] = item.case_ee_id.map((data: any) => data.ee_name).toString();
        } else {
          item['ee_name'] = item.case_ee_id?.ee_name;
        }
        if (item.case_respondent_id?.length > 0) {
          let res = item.case_respondent_id.map((data: any) => data.res_name)
          item['respondent'] = res.join(", ")
        }
        return item;
      });
    }
  }

  exportToCsv() {
    this.CaseS.exportToCsv(this.getDataVal, 'cases')
  }
}
